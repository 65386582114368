import React, { useState } from 'react';
import { IoLogoFacebook, IoLogoTwitter, IoLogoInstagram, IoLogoLinkedin, IoLogoYoutube } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { translation } from '../../i18n';
import { setLang } from '../../actions';
import LogoEnd from '../../images/logo-end.png';
import './style.scss';

const Footer = () => {

    const dispatch = useDispatch();
    const lang = useSelector((state) => state.language);

    return (
        <section id="footer">
            <div className="social-links">
                <img className="logo-footer" src={LogoEnd} alt="logo-footer" />
                <div className="container">
                    <ul>
                        <li 
                             onClick={() => dispatch(setLang("EN"))}
                             className={ lang?.language=="EN" ? "active" : "" }
                        ><p>EN</p></li>
                        <li 
                             onClick={() => dispatch(setLang("FR"))}
                             className={ lang?.language=="FR" ? "active" : "" }
                        ><p>FR</p></li>
                        <li 
                             onClick={() => dispatch(setLang("DE"))}
                             className={ lang?.language=="DE" ? "active" : "" }
                        ><p>DE</p></li>
                        <li 
                             onClick={() => dispatch(setLang("IT"))}
                             className={ lang?.language=="IT" ? "active" : "" }
                        ><p>IT</p></li>
                    </ul>
                </div>
                <div>
                    <IoLogoFacebook className="fa fa-facebook" />
                    <IoLogoInstagram className="fa fa-instagram" />
                    <IoLogoTwitter className="fa fa-twitter" />
                    <IoLogoLinkedin className="fa fa-linkedin" />
                    <IoLogoYoutube className="fa fa-youtube" />
                </div>
                <p className="copyright">HPP © {new Date().getFullYear()} &nbsp;
                {translation(lang?.language, "_rights")}.</p>
            </div>
        </section>
    )
}

export default Footer
