import React, { useState, useEffect } from 'react';
import Logo from '../../images/logo.png';
import { IconContext } from 'react-icons/lib';
import { Link as LinkR } from 'react-router-dom';
import './style.scss';



const CostumNavBar = () => {

    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if(window.scrollY>=10) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    }

    useEffect(() => {
        let unmounted = false;
        if(!unmounted) window.addEventListener('scroll', changeNav);
        return () => { unmounted = true };
    }, [])

    return (
        <IconContext.Provider value={{ color: '#fff' }}>
            <nav id="nav"
             style={ scrollNav? {background : 'transparent', visibility: 'hidden'} : {background : 'transparent'} }
            >
                <div className="navContainer">
                    <LinkR className="navLogo" to='/'><img src={Logo} alt="logo" className="logo" /></LinkR>
                </div>
            </nav>
        </IconContext.Provider>
    )
}

export default CostumNavBar
