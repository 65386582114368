import React, { useState, useEffect } from 'react';
import Logo from '../../images/logo.png';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import { FaBars } from 'react-icons/fa';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import { useSelector } from 'react-redux';
import { translation } from '../../i18n';
import './style.scss';



const NavBar = ({ toggle }) => {

    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if(window.scrollY>=100) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    }

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    useEffect(() => {
        let unmounted = false;
        if(!unmounted) window.addEventListener('scroll', changeNav);
        return () => { unmounted = true };
    }, []);

    const langState = useSelector((state) => state.language);

    

    return (
        <IconContext.Provider value={{ color: '#fff' }}>
            <nav id="nav" style={ scrollNav? {background : '#000'} : {background : 'transparent'} }>
                <div className="navContainer">
                    <LinkR className="navLogo" to='/' onClick={toggleHome}><img src={Logo} alt="logo" className="logo" /></LinkR>
                    <div className="mobileIcon" onClick={toggle}>
                        <FaBars />
                    </div>
                    <ul className="navMenu">
                        <li className="navItem">
                            <LinkS 
                                className="navLinks"
                                to='about'
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-50}
                                activeClass='active'
                            >{translation(langState?.language, "_about")}</LinkS>
                        </li>
                        <li className="navItem">
                            <LinkS 
                                className="navLinks"
                                to='feature'
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-50}
                                activeClass='active'
                            >{translation(langState?.language, "_features")}</LinkS>
                        </li>
                        <li className="navItem">
                            <LinkS 
                                className="navLinks"
                                to='product'
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-50}
                                activeClass='active'
                            >{translation(langState?.language, "_products")}</LinkS>
                        </li>
                        <li className="navItem">
                            <LinkS 
                                className="navLinks"
                                to='contact'
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-50}
                                activeClass='active'
                            >{translation(langState?.language, "_contact")}</LinkS>
                        </li>
                        <li className="navItem">
                            <LinkS 
                                className="navLinks"
                                to='certification'
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-50}
                                activeClass='active'
                            >{translation(langState?.language, "_certifications")}</LinkS>
                        </li>
                        <li className="navItem">
                            <LinkR className="navLinks" to="/covidstats">{translation(langState?.language, "_covidstats")}</LinkR>
                        </li>
                    </ul>
                </div>
            </nav>
        </IconContext.Provider>
    )
}

export default NavBar
