import React, { useState } from 'react';
import { FaAddressBook, FaPhoneSquareAlt, FaFax, FaWhatsapp } from 'react-icons/fa';
import { IoMail } from 'react-icons/io5';
import emailjs from 'emailjs-com';
import { useSelector } from 'react-redux';
import { translation } from '../../i18n';
import './style.scss';

const Contact = () => {

    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);
    const langState = useSelector((state) => state.language);

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_xydcabm', 'template_nb4psnm', e.target, 'user_M2KKFokj7ziODFmZHEApm')
          .then((result) => {
              console.log(result.text);
              setSuccess(true);
              setFailure(false);
          }, (error) => {
              console.log(error.text);
              setSuccess(false);
              setFailure(true);
          });
          e.target.reset();
      }

    return (
        <section id="contact">
        <div className="title-text">
            <p>{translation(langState?.language, "_contact")}</p>
            <h1>{translation(langState?.language, "_getintouch")}</h1>
        </div>
       <div className="contact-form">
           <div className="first-container">
               <div className="info-container">
                   <div>
                       <h3><FaAddressBook className="fa" />&nbsp;{translation(langState?.language, "_address")}</h3>
                       <p>Cite Elfateh,  Matmata Nouvelle 6044 - Gabes, {translation(langState?.language, "_tunisia")}</p>
                   </div>
                   <div>
                       <h3><FaPhoneSquareAlt className="fa" />&nbsp;{translation(langState?.language, "_phone")}</h3>
                       <p>(+216) 75 23 17 00</p> 
                       <p>(+216) 25 82 23 23</p> 
                       <p>(+216) 99 10 72 17</p> 
                   </div>
                   <div>
                       <h3><FaFax className="fa" />&nbsp;{translation(langState?.language, "_fax")}</h3>
                       <p>(+216) 75 23 16 33</p>
                   </div>
                   <div>
                       <h3><FaWhatsapp className="fa" />&nbsp;{translation(langState?.language, "_whatsapp")}</h3>
                       <p>(+216) 99 10 72 17</p>
                   </div>
                   <div>
                       <h3><IoMail className="fa" />&nbsp;{translation(langState?.language, "_generalsupport")}</h3>
                       <p>contact@hpp.com.tn</p>
                   </div>
                   <div>
                       <h3><IoMail className="fa" />&nbsp;{translation(langState?.language, "_export")}</h3>
                       <p>export@hpp.com.tn</p>
                   </div>
               </div>
           </div>
           <div className="second-container">
               <h2>{translation(langState?.language, "_sendmessage")}</h2>
               <form onSubmit={sendEmail}>
                   <div className="form-group">
                        <label htmlFor="fullname-input">{translation(langState?.language, "_fullname")}</label>
                        <input id="fullname-input"  type="text" placeholder={translation(langState?.language, "_fullnameholder")} name="name" required />
                   </div>
                   <div className="form-group">
                       <label htmlFor="email-input">{translation(langState?.language, "_email")}</label>
                       <input id="email-input" type="email" placeholder="Eg. example@gmail.com" name="email" required />
                   </div>
                   <div className="form-group">
                        <label htmlFor="email-input">{translation(langState?.language, "_phonenumber")}</label>
                        <input type="number" id="phone-input" placeholder="Eg. 00 1 800 100 2654" name="phone" required />
                   </div>
                   <div className="form-group">
                        <label htmlFor="fullname-input">{translation(langState?.language, "_subject")}</label>
                        <input id="object-input"  type="text" placeholder={translation(langState?.language, "_subjectholder")} name="subject" required />
                   </div>
                   <div className="form-group">
                       <label htmlFor="message">{translation(langState?.language, "_message")}</label>
                       <textarea id="message-textarea"placeholder={translation(langState?.language, "_typemessage")} name="message" required></textarea>
                   </div>
                   <input type="submit" value={translation(langState?.language, "_send")} />
                   <p className="successText" style={ success? { display: 'inline' } : { display: 'none' } }>{translation(langState?.language, "_messagesuccess")}</p>
                   <p className="failureText" style={ failure? { display: 'inline' } : { display: 'none' } }>{translation(langState?.language, "_somethingwrong")}</p>
               </form>
           </div>
        </div>
      </section>
    )
}

export default Contact
