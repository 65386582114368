import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import { useSelector } from 'react-redux';
import { translation } from '../../i18n';
import './style.scss';

const SideBar = ({ isOpen, toggle }) => {

    const langState = useSelector((state) => state.language);

    return (
        <aside
             onClick={toggle}
             className="sidebarContainer"
             style={ isOpen? { opacity: '100%', top: '0' } : { opacity: '0', top: '-100%' } }
        >
            <div className="icon">
                <FaTimes className="closeIcon" />
            </div>
            <div className="sidebarWrapper">
                <ul className="sidebarMenu">
                    <LinkS className="sidebarLink" to='about' offset={-50} onClick={toggle}>
                        {translation(langState?.language, "_about")}
                    </LinkS>
                    <LinkS className="sidebarLink" to='feature' offset={-50}  onClick={toggle}>
                        {translation(langState?.language, "_features")}
                    </LinkS>
                    <LinkS className="sidebarLink" to='product' offset={-50} onClick={toggle}>
                        {translation(langState?.language, "_products")}
                    </LinkS>
                    <LinkS className="sidebarLink" to='contact' offset={-50} onClick={toggle}>
                        {translation(langState?.language, "_contact")}
                    </LinkS>
                    <LinkS className="sidebarLink" to='certification' offset={-50} onClick={toggle}>
                        {translation(langState?.language, "_certifications")}
                    </LinkS>
                    <LinkR className="sidebarLink" to='/covidstats'>
                        {translation(langState?.language, "_covidstats")}
                    </LinkR>
                </ul>
            </div>

        </aside> 
    )
}

export default SideBar
