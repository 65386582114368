import { langConstants } from "./constants"

export const setLang = (lang) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: langConstants.SET_LANG,
                language: lang
            });
            localStorage.setItem("language", lang);
        } catch (error) {
            dispatch({
                type: langConstants.SET_LANG_FAILURE,
                error: error
            });
        }
    }
}