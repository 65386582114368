import React, { useState } from 'react'
import About from '../AboutSection';
import { homeObjOne } from '../AboutSection/Data';
import Certification from '../CertificationSection';
import Contact from '../ContactSection';
import Feature from '../FeatureSection';
import Footer from '../Footer';
import NavBar from '../NavBar';
import Product from '../ProductSection';
import SideBar from '../SideBar';
import Welcome from '../WelcomeSection';

const Home = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <SideBar isOpen={isOpen} toggle={toggle} />
            <NavBar toggle={toggle} />
            <Welcome />
            <About {...homeObjOne} />
            <Feature />
            <Product />
            <Contact />
            <Certification />
            <Footer />
        </>
    )
}

export default Home
