import React, { useEffect } from 'react';
import CovidWelcome from '../CovidWelcomeSection';
import Footer from '../Footer';
import Stats from '../StatsSection';
import CostumNavBar from '../NavBar/CostumNav';

const CovidStats = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <>
            <CostumNavBar />
            <CovidWelcome />
            <Stats />
            <Footer />
        </>
    )
}

export default CovidStats
