import React from 'react';
import Video from '../../videos/vid-1.mp4';
import { Link as LinkS } from 'react-scroll';
import SideLogo from '../../images/side-logo.png';
import { useSelector } from 'react-redux';
import { translation } from '../../i18n';
import './style.scss';


const Welcome = () => {

    const langState = useSelector((state) => state.language);

    return (
        <section id="home">
            <div className="homeBg">
                <video className="videoBg" autoPlay controlsList="nodownload" loop muted src={Video} type='video/mp4' controlsList="nodownload" />
            </div>
            <div className="homeContent">
                <h1><img src={ SideLogo } alt="side-logo" />HPP</h1>
                <p>{translation(langState?.language, "_wear")} <br/>&<br/> {translation(langState?.language, "_worry")}</p>
                <div className="btnWrapper">
                <LinkS 
                    className="anchor"
                    to='about'
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-50}
                    activeClass='active'                               
                ><span></span>{translation(langState?.language, "_findout")} </LinkS>
                <LinkS 
                    className="anchor"
                    to='feature'
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-50}
                    activeClass='active'                               
                ><span></span>{translation(langState?.language, "_readmore")}</LinkS>
                </div>
            </div>
        </section>
    )
}

export default Welcome
