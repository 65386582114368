import SVG from '../../images/medical.svg';


export const homeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Health Production Product',
    headline: 'Manufacturer And Exporter Company',
    description: "HEALTH PRODUCTION PRODUCTS “HPP” is a professional ISO13485:2016 and ISO9001:2015 certified company, specializing in the manufacturing and marketing of medical devices for the health sector and protective items for the human and his environment. Our only objective is the satisfaction of our customers and to meet their requirements. If you want to ask a question, ask for advice, or information: do not hesitate to contact us.",
    imgStart: false,
    img: SVG,
    alt: 'Medical Assistance',
    dark: false,
    primary: false,
    darkText: true
};