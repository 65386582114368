import React from 'react';
import ElasticMask from '../../images/elastic-surgical-mask.jpg';
import TieMask from '../../images/tie-surgical-mask.jpg';
import ClipCap from '../../images/clip-cap.jpg';
import PPCap from '../../images/pp-cap.jpg';
import Hood from '../../images/hood.jpg';
import Shoe from '../../images/shoe.jpg';
import Coat from '../../images/coat.jpg';
import SmsGown from '../../images/sms-gown.jpg';
import PPGown from '../../images/pp-gown.jpg';
import PP4Apron from '../../images/pp-4-apron.jpg';
import PPCoverall from '../../images/pp-coverall.jpg'
import BedKit from '../../images/bed.jpg';
import BouffantCap from '../../images/bouffant-cap.jpg';
import { useSelector } from 'react-redux';
import { translation } from '../../i18n';
import './style.scss';
const Product = () => {

    const langState = useSelector((state) => state.language);

    return (
        <section id="product">
            <div className="title-text">
                <p>{translation(langState?.language, "_products")}</p>
                <h1>{translation(langState?.language, "_weprovide")}</h1>
            </div>
            <div className="product-box">
                <div className="single-product">
                    <img src={ElasticMask} alt="elastic-mask" />
                    <div className="overlay"></div>
                    <div className="product-desc">
                        <h3>{translation(langState?.language, "_elasticsurgicalmask")}</h3>
                        <hr />
                        <p>{translation(langState?.language, "_elasticsurgicalmask_desc")}</p>
                    </div>
                </div>
                <div className="single-product">
                    <img src={TieMask} alt="tie-mask" />
                    <div className="overlay"></div>
                    <div className="product-desc">
                        <h3>{translation(langState?.language, "_tiesurgicalmask")}</h3>
                        <hr />
                        <p>{translation(langState?.language, "_tiesurgicalmask_desc")}</p>
                    </div>
                </div>
                <div className="single-product">
                    <img src={ClipCap} alt="clip-cap" />
                    <div className="overlay"></div>
                    <div className="product-desc">
                        <h3>{translation(langState?.language, "_ppdisposablecap")}</h3>
                        <hr />
                        <p>{translation(langState?.language, "_ppdisposablecap_desc")}</p>
                    </div>
                </div>
                <div className="single-product">
                    <img src={BouffantCap} alt="bouffant-cap" />
                    <div className="overlay"></div>
                    <div className="product-desc">
                        <h3>{translation(langState?.language, "_bouffantcap")}</h3>
                        <hr />
                        <p>{translation(langState?.language, "_bouffantcap_desc")}</p>
                    </div>
                </div>
                <div className="single-product">
                    <img src={PPCap} alt="pp-cap" />
                    <div className="overlay"></div>
                    <div className="product-desc">
                        <h3>{translation(langState?.language, "_ppcap")}</h3>
                        <hr />
                        <p>{translation(langState?.language, "_ppcap_desc")}</p>
                    </div>
                </div>
                <div className="single-product">
                    <img src={Hood} alt="hood" />
                    <div className="overlay"></div>
                    <div className="product-desc">
                        <h3>{translation(langState?.language, "_ppnonwovenhood")}</h3>
                        <hr />
                        <p>{translation(langState?.language, "_ppnonwovenhood_desc")}</p>
                    </div>
                </div>
                <div className="single-product">
                    <img src={Shoe} alt="shoe-cover" />
                    <div className="overlay"></div>
                    <div className="product-desc">
                        <h3>{translation(langState?.language, "_peovershoe")}</h3>
                        <hr />
                        <p>{translation(langState?.language, "_peovershoe_desc")}</p>
                    </div>
                </div>
                <div className="single-product">
                    <img src={SmsGown} alt="sms-gown" />
                    <div className="overlay"></div>
                    <div className="product-desc">
                        <h3>{translation(langState?.language, "_smsisolationgown")}</h3>
                        <hr />
                        <p>{translation(langState?.language, "_smsisolationgown_desc")}</p>
                    </div>
                </div>
                <div className="single-product">
                    <img src={PPGown} alt="pp-isolation-gown" />
                    <div className="overlay"></div>
                    <div className="product-desc">
                        <h3>{translation(langState?.language, "_ppisolationgown")}</h3>
                        <hr />
                        <p>{translation(langState?.language, "_ppisolationgown_desc")}</p>
                    </div>
                </div>
                <div className="single-product">
                    <img src={PP4Apron} alt="4-button-apron" />
                    <div className="overlay"></div>
                    <div className="product-desc">
                        <h3>{translation(langState?.language, "_ppapron")}</h3>
                        <hr />
                        <p>{translation(langState?.language, "_ppapron_desc")}</p>
                    </div>
                </div>
                <div className="single-product">
                    <img src={Coat} alt="sf-coverall" />
                    <div className="overlay"></div>
                    <div className="product-desc">
                        <h3>{translation(langState?.language, "_sfcoverall")}</h3>
                        <hr />
                        <p>{translation(langState?.language, "_sfcoverall_desc")}</p>
                    </div>
                </div>
                 <div className="single-product">
                    <img src={PPCoverall} alt="elastic-coverall" />
                    <div className="overlay"></div>
                    <div className="product-desc">
                        <h3>{translation(langState?.language, "_ppcoverall")}</h3>
                        <hr />
                        <p>{translation(langState?.language, "_ppcoverall_desc")}</p>
                    </div>
                </div>
                <div className="single-product">
                    <img src={BedKit} alt="bedding-kit" />
                    <div className="overlay"></div>
                    <div className="product-desc">
                        <h3>{translation(langState?.language, "_beddingkit")}</h3>
                        <hr />
                        <p>{translation(langState?.language, "_beddingkit_desc")}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Product
