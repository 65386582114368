const EN = require('./languages/en.json');
const FR = require('./languages/fr.json');
const DE = require('./languages/de.json');
const IT = require('./languages/it.json');

export const translation = (language, key) => {
    if (language == 'EN') {
      return EN[key] || `missing translation for key: ${key}`
    } 
    if (language == 'FR') {
      return FR[key] || `missing translation for key: ${key}`
    }
    if (language == 'DE') {
      return DE[key] || `missing translation for key: ${key}`
    }
    if (language == 'IT') {
      return IT[key] || `missing translation for key: ${key}`
    }
}
  
