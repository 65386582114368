import React, { useState, useRef } from 'react';
import  { FaDownload, FaVideo } from 'react-icons/fa';
import IntroVideo from '../../videos/intro.mp4';
import { useSelector } from 'react-redux';
import { translation } from '../../i18n';
import './style.scss';

const About = ({ id, topLine,lightText, darkText, headline,
    description, img, alt }) => {

    const [switcher, setSwitcher] = useState(false);
    const videoRef = useRef(null);

    const langState = useSelector((state) => state.language);

    const switchVideo = () => {
        setSwitcher(false);
        videoRef.current.pause();
    }
 
    return (
        <section id="about">
            <div className="title-text">
                    <p>{translation(langState?.language, "_about")}</p>
                    <h1>{translation(langState?.language, "_whoarewe")}</h1>
            </div>
            <div className="wrapper-video" style={switcher? { transition: "visibility 0s, opacity .2s ease-in-out", visibility: "visible", opacity: "1" } : { visibility: "hidden", opacity: "0" } }>      
                <label className="btn-close" htmlFor="modal" onClick={switchVideo}>×</label>
                <label className="overlay-video" htmlFor="modal"></label>
                <div className="container-video" style={switcher? { transform: "translateY(0)", opacity: "1" } : {  opacity: "0" }}>
                    <div className="modal-bg"></div>
                    <video className="videoBg-Full" ref={videoRef} controls disablePictureInPicture src={IntroVideo} controlsList="nodownload" type='video/mp4' />
                </div>
            </div>  
            <div 
                id={id}
                style={{ background: '#fff' }}
                className="infoContainer"
            >
                <div className="infoWrapper">
                    <div 
                        className="infoRow"
                        style={ { gridTemplateAreas: 'col1 col2' }  }
                    >
                        <div className="column1">
                            <div className="textWrapper">
                                <p className="topLine">{topLine}</p>
                                <h1 
                                    style={{ color: '#010606' }}
                                    className="heading"
                                >{translation(langState?.language, "_about_title")}</h1>
                                <p
                                    style={{ color: '#010606' }}
                                    className="subTitle"
                                >{translation(langState?.language, "_about_intro")}</p>
                                <div className="btnWrap">
                                    <a href="https://drive.google.com/uc?export=download&id=1z8IfLL7nvRo0p1EjDbQsaBatesPQ_30V" className="btn" download>{translation(langState?.language, "_downloadbroch")}&nbsp;<FaDownload /></a>
                                    <a onClick={() => setSwitcher(true)} className="btn">{translation(langState?.language, "_watchvid")}&nbsp;<FaVideo /></a>
                                </div>
                            </div>
                        </div>
                        <div className="column2">
                            <div className="imgWrap">
                                <img src={img} alt={alt} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
