import React from 'react';
import { FaShieldAlt, FaCheckCircle, FaMoneyBill } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { translation } from '../../i18n';
import Img from '../../images/prod.jpg';
import './style.scss';

const Feature = () => {

    const langState = useSelector((state) => state.language);

    return (
        <section id="feature">
            <div className="title-text">
                <p>{translation(langState?.language, "_features")}</p>
                <h1>{translation(langState?.language, "_whychoose")}</h1>
            </div>
            <div className="feature-box">
                <div className="features">
                    <h1>{translation(langState?.language, "_experiencedstaff")}</h1>
                    <div className="features-desc">
                        <div className="feature-icon">
                            <FaShieldAlt  className="fa" />
                        </div>
                        <div className="feature-text">
                            <p>{translation(langState?.language, "_experiencedstaff_desc")}</p>
                        </div>
                    </div>
                    <h1>{translation(langState?.language, "_certified")}</h1>
                    <div className="features-desc">
                        <div className="feature-icon">
                            <FaCheckCircle  className="fa" />
                        </div>
                        <div className="feature-text">
                            <p>{translation(langState?.language, "_certified_desc")}</p>
                        </div>
                    </div>
                    <h1>{translation(langState?.language, "_affordablecost")}</h1>
                    <div className="features-desc">
                        <div className="feature-icon">
                            <FaMoneyBill  className="fa fa-shield" />
                        </div>
                        <div className="feature-text">
                            <p>{translation(langState?.language, "_experiencedstaff_desc")}</p>
                        </div>
                    </div>
                </div>
                <div className="features-img">
                    <img src={Img} alt="inter-medical" />
                </div>
            </div>
        </section>
    )
}

export default Feature
