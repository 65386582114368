import React from 'react';
import Slider from "react-slick";
import Logo1 from '../../images/nelson-logo.png';
import Logo2 from '../../images/chemica-logo.png';
import Logo3 from '../../images/qacs-logo.png';
import Logo4 from '../../images/qatechnic-new-logo.png';
import { useSelector } from 'react-redux';
import { translation } from '../../i18n';
import './style.scss';

const Certification = () => {

    const langState = useSelector((state) => state.language);

    let settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        dots: false,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 520,
            settings: {
              slidesToShow: 2
            }
          }
        ]
      };

    return (
        <section id="certification">
        <div className="title-text">
            <p>{translation(langState?.language, "_certifications")}</p>
            <h1>{translation(langState?.language, "_provenquality")}</h1>
        </div>
        <div className="client-area">
            <div className="container">
                <Slider {...settings} className="logo-area slider">
                    <div className="slide"><img src={Logo1} /></div>
                    <div className="slide"><img src={Logo2} /></div>
                    <div className="slide"><img src={Logo3} /></div>
                    <div className="slide"><img src={Logo4} /></div>
                </Slider>
            </div>
        </div>
        </section>
    )
}

export default Certification
