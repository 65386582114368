import React, { useState } from 'react';
import { Link }  from 'react-scroll';
import Video from '../../videos/vid-2.mp4'
import { MdKeyboardArrowRight,  MdArrowForward } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { translation } from '../../i18n';
import './style.scss';

const CovidWelcome = () => {

    const langState = useSelector((state) => state.language);

    const [hover, setHover] = useState(false);
    const onHover = () => {
        setHover(!hover);
    }

    return (
        <section id="covHome">
            <div className="covHomeBg">
                <video className="covVideoBg" autoPlay controlsList="nodownload" loop muted src={Video} type='video/mp4' controlsList="nodownload" />
            </div>
            <div className="covHomeContent">
                <h1>{translation(langState?.language, "_covidglobal")}</h1>
                <q>{translation(langState?.language, "_quote")}</q><span>Sun Tzu</span>
                <div className="covBtnWrapper">
                    <Link className="buttonLink" to='stats' onMouseEnter={onHover}
                        onMouseLeave={onHover}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={0}
                        activeClass='active'
                     >
                        {translation(langState?.language, "_findout")} {hover? <MdKeyboardArrowRight className="arr-right" /> : <MdArrowForward className="arr-for" /> }
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default CovidWelcome
