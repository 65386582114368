import { langConstants } from "../actions/constants";

const initialState = {
    language: localStorage.getItem("language")? localStorage.getItem("language") : 'EN',
    error: null
  };
  
  export default (state = initialState, action) => {
    switch(action.type) {
        case langConstants.SET_LANG :
            state = {
                ...state, 
                language: action.language
            }
            break;
        case langConstants.SET_LANG_FAILURE : 
            state = {
                ...state,
                error: action.error
            }
            break;
    }
    return state;
  }
  